import '../css/style.css';

import { $, $$ } from './modules/bling'; //looks like jquery but it's not


import dropdownMenu from './modules/dropdownMenu';
import activeNavItem from './modules/activeNavItem';
import accordion from './modules/accordion';

dropdownMenu();
activeNavItem();
accordion();
